@import '../../sass/mixins.scss';

:local(.root) {
  @include flexCenter();
}

:local(.card) {
  min-width: 300px;
}

:local(.status) {
  font-size: 52px;
}

:local(.footer) {
  margin-top: 50px;
}

:local(.message),
:local(.status),
:local(.footer) {
  text-align: center;
}
